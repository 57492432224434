import Image from "next/image";
import React from "react";

export const InternalServerError = () => {
  return (
    <div className="grid grid-cols-2 gap-[3.75rem] lg:gap-[6.5rem]  items-center p-4">
      <div className="col-span-2 md:col-span-1">
        <p className="text-primary text-[1.125rem]">Error 500</p>
        <p className="my-4 text-grayD2 text-[2.5rem] font-semibold max-w-[25.375rem]">
          Opps, Internal server error…
        </p>
        <p className="max-w-[26.5rem] text-grayD3 text-[1.125rem]">
          We apologies and are fixing the error. Please try again after
          sometimes.
        </p>
      </div>

      <div className="col-span-2 md:col-span-1">
        <Image
          width={530}
          height={400}
          src="/assets/error-images/500.png"
          alt="500-error"
        />
      </div>
    </div>
  );
};
