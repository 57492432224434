import {
  setCurrentProduct,
  clearCurrentProduct,
} from "@/store/feature/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFetchSingleProduct } from "repo/product";
import Overview from "../product-details/Overview";
import { useEffect } from "react";
import { CartModalSkeleton } from "../skeleton/CartModalSkeleton";
import { InternalServerError } from "../error/InternalServerError";
import { useRef } from "react";

function AddToCartModal({ refetchCart = null }) {
  const dispatch = useDispatch();
  let product = useSelector((state) => state.product.currentProduct);
  const modalCloseRef = useRef(null);

  const {
    refetch: productRefetch,
    isError,
    isLoading,
    isRefetching,
  } = useFetchSingleProduct(product?.slug, {
    onSuccess: ({ product }) => {
      dispatch(setCurrentProduct(product));
    },
    enabled: false,
  });

  function AddToCartCallback() {
    if (refetchCart) refetchCart();
    modalCloseRef.current.click();
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (product?.slug && product?.variations.length === 0) {
      productRefetch();
    }
  }, [product]);

  const isLoadingOrRefetching = isLoading || isRefetching || !product;

  return (
    <>
      <input type="checkbox" id="add-to-cart-modal" className="modal-toggle" />
      <div className="modal overflow-hidden">
        <div
          className={`min-h-screen lg:min-h-0 modal-box relative w-full md:max-w-4xl lg:max-w-7xl xl:max-w-[78rem] 2xl:max-w-6xl rounded-none lg:rounded-md`}
        >
          <label
            htmlFor="add-to-cart-modal"
            onClick={() => dispatch(clearCurrentProduct())}
            ref={modalCloseRef}
            className="absolute right-5 top-2 cursor-pointer text-[#414E5F] font-bold p-2"
          >
            ✕
          </label>

          <div
            className={`pt-[1.875rem] lg:overflow-hidden ${
              product && "mb-[2.15rem] pt-[1.875rem]"
            }`}
          >
            {isError ? (
              <InternalServerError />
            ) : isLoadingOrRefetching ? (
              <CartModalSkeleton />
            ) : (
              <Overview
                product={product}
                isInModal
                addToCartCallback={refetchCart ? AddToCartCallback : null}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddToCartModal;
