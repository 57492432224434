import { useEffect, useState } from "react";

function useTextOverflow(textRef) {
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    const element = textRef.current;

    if (element) {
      const isOverflowed = element.scrollHeight > element.clientHeight;

      setIsOverflowed(isOverflowed);
    }
  }, [textRef]);

  return isOverflowed;
}

export default useTextOverflow;
