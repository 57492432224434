import apiClient from "@/services/api-client";
import { useQuery } from "@tanstack/react-query";
import { errorLogger } from "@/utils/helpers/logger";

export const fetchSingleProduct = (product_slug) => {
  return apiClient({
    url: `/v1/products/${product_slug}`,
    method: "GET",
  });
};

export const useFetchSingleProduct = (productSlug, useQueryOptions) => {
  return useQuery({
    queryKey: ["single-product", productSlug],
    queryFn: () => fetchSingleProduct(productSlug),
    ...useQueryOptions,
    select: (data) => {
      const productData = data.data.data;

      return {
        product: productData.product,
        relatedProducts: productData.related_products,
        schema: productData.schema,
        metaData: productData.metadata,
        promotionalData: productData.promotional_banner_info,
      };
    },
    onError: (error) => {
      errorLogger.logApiError(error);
    },
  });
};
