import React from "react";
import PropTypes from "prop-types";
import InlineLoader from "@/icons/inline-loader.svg";
import classNames from "classnames";

function SecondaryBtn({
  onClick,
  btnText,
  width,
  height,
  isLoading,
  disabled,
  className = "",
  ...otherProps
}) {
  return (
    <button
      type="button"
      className={`bg-[#EBEDF0] text-[#1F2A3B] rounded-[10px] font-medium hover:bg-[#E6E8EB] active:bg-[#E6E8EB] relative ${className}`}
      style={{ width: width, height: height }}
      {...otherProps}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading && (
        <InlineLoader className="inline-block h-[3rem] w-[3rem] absolute top-[calc(50%_-_1.5rem)] left-[calc(50%_-_1.5rem)]" />
      )}
      <p
        className={classNames(
          {
            invisible: isLoading,
          },
          "inline-block"
        )}
      >
        {btnText}
      </p>
    </button>
  );
}

export default SecondaryBtn;

SecondaryBtn.propTypes = {
  onClick: PropTypes.func,
  btnText: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};
