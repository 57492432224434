import apiClient from "@/services/api-client";
import successToast, { errorToast, noInternetErrorToast } from "@/utils/toast";
import store from "@/store/index";
import en from "@/localization/en.json";
import { sendFPixelEvent, fbEvents } from "lib/fpixel";
import { errorLogger } from "@/utils/helpers/logger";

export const addToCart = async (data) => {
  try {
    const res = await apiClient({
      method: "post",
      url: "/v2/carts",
      data: data,
    });

    if (res && res.status === 200) {
      sendFPixelEvent(fbEvents.addToCart);
      store.dispatch(
        {
          type: "cart/updateCartItemCount",
          payload: res?.data?.data?.cart?.products?.length,
        },
        { root: true }
      );
      return res.data;
    }
  } catch (error) {
    errorLogger.logApiError(error);
    if (error.code === "ERR_NETWORK") noInternetErrorToast();
    else {
      let message = error?.response?.data?.errors[0]?.message;
      errorToast(message);
    }
  }
};

export const fetchCartInfo = async (headers = {}) => {
  try {
    const res = await apiClient({
      method: "get",
      url: "/v2/carts",
      headers,
    });

    return res.data;
  } catch (error) {
    errorLogger.logApiError(error);
    return error;
  }
};

export const removeCartProduct = async (product) => {
  try {
    const res = await apiClient({
      method: "delete",
      url: "/v2/carts",
      data: product,
    });

    if (res.status === 200) {
      successToast(en["myCart.removed_successfully"]);
      store.dispatch(
        {
          type: "cart/updateCartItemCount",
          payload: res?.data?.data?.cart?.products?.length,
        },
        { root: true }
      );

      return res.data;
    }
  } catch (error) {
    errorLogger.logApiError(error);
  }
};

export const updateCartByAddress = async (address_slug) => {
  try {
    const res = await apiClient({
      method: "post",
      url: `/v1/carts-address/${address_slug}`,
    });

    if (res.status === 202) {
      return res;
    }
  } catch (error) {
    errorLogger.logApiError(error);
  }
};

export const updateCartByDeliveryMethod = async (pickupType) => {
  try {
    const res = await apiClient({
      method: "post",
      url: `/v1/carts-delivery-method`,
      data: {
        methods: pickupType == 2,
      },
    });

    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    errorLogger.logApiError(error);
  }
};
