import ShowMoreButton from "@/components/button/showmoreBtn";
import classNames from "classnames";
import useTextOverflow from "hooks/useTextOverflow";
import { PropTypes } from "prop-types";
import { useRef, useState } from "react";

function HtmlRenderer({ htmlData, visibleLineClass = "line-clamp-3" }) {
  const [isExpended, setIsExpended] = useState(false);
  const textContainerRef = useRef(null);

  const isOverflowed = useTextOverflow(textContainerRef);

  return (
    <section>
      <div
        ref={textContainerRef}
        className={classNames(
          {
            [`${visibleLineClass} overflow-hidden`]: !isExpended,
            "h-auto overflow-auto": isExpended,
          },
          "admin-text-editor"
        )}
      >
        <div dangerouslySetInnerHTML={{ __html: htmlData }} />
      </div>

      {isOverflowed && (
        <ShowMoreButton
          isExpended={isExpended}
          onClick={() => setIsExpended(!isExpended)}
        />
      )}
    </section>
  );
}

export default HtmlRenderer;

HtmlRenderer.propTypes = {
  htmlData: PropTypes.string,
};
