import { addToCart } from "@/api/cart";
import { addToWishList, removeWishList } from "@/api/wishlist";
import Styles from "@/components/product-details/index.module.css";
import MinusIcon from "@/icons/minus.svg";
import PlusIcon from "@/icons/plus.svg";
import en from "@/localization/en.json";
import { productPlaceholderlargeImage } from "@/utils/constants/productImagePlaceholder.constants";
import { isAuthenticated } from "@/utils/helpers/auth";
import { errorToast, successToast, warningToast } from "@/utils/toast";
import classNames from "classnames";
import _ from "lodash";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import PrimaryBtn from "../button/primaryBtn";
import TertiaryBtn from "../button/tertiaryBtn";
import InlineLoader from "../loader/InlineLoader";
import Rating from "../data-display/Rating";
import SecondaryBtn from "../button/secondaryBtn.jsx";
import { usePostProductRequest } from "@/api/client/product";
import HtmlRenderer from "../data-display/html-renderer";
import ProductDetailsBanner from "./ProductDetailsBanner";

export default function Overview({
  product,
  isInModal = false,
  smallImageSize = 96,
  promotionalBannerInfo,
  addToCartCallback = null,
}) {
  const router = useRouter();
  const { pictures } = product;
  const [imageGallery, setImageGallery] = useState(() => pictures);
  const [isAddToCartLoading, setIsAddToCartLoading] = useState(false);
  const [isBuyNowLoading, setIsBuyNowLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [activeImage, setActiveImage] = useState(() =>
    imageGallery?.length ? imageGallery[0] : ""
  );

  const [cartedItemsCount, setCartedItemsCount] = useState(1);

  const [localProductData, setLocalProductData] = useState(product);

  // PRODUCT COMBINATION VARIATION COMBINATION START
  const [userSelectionValue, setUserSelectionValue] = useState({});
  const [selectedCombination, setSelectedCombination] = useState(
    product.default_variation
  );
  const [isWishlist, setIsWishlist] = useState(
    product.default_variation.in_wishList
  );

  const minAvailabilityToShow = 20;
  const dangerStockAmount = 10;

  useEffect(() => {
    setIsWishlist(product.default_variation.in_wishList);
  }, [product]);

  const inventory = selectedCombination.inventory;
  const isDecrBtnDisabled = inventory === 0 || cartedItemsCount === 1;
  const isIncrBtnDisabled =
    inventory === 0 ||
    cartedItemsCount === inventory ||
    cartedItemsCount >= product.maximum_cart_quantity;

  useEffect(() => {
    if (Object.keys(selectedCombination).length) {
      setIsWishlist(() => selectedCombination?.in_wishList);
      if (selectedCombination?.pictures?.length) {
        setImageGallery(selectedCombination.pictures);
        setActiveImage(selectedCombination.pictures[0]);
      } else {
        setImageGallery(pictures);
        setActiveImage(pictures[0]);
      }
    }
  }, [selectedCombination]);

  useEffect(() => {
    findVariationCombination(userSelectionValue);
  }, [userSelectionValue]);

  useEffect(() => {
    handleFirstCombination(product.default_variation.values);
    setImageGallery(pictures);
    setLocalProductData(product);
    setActiveImage(imageGallery?.length ? imageGallery[0] : "");
    setSelectedCombination(product.default_variation);
  }, [product]);

  const { isLoading: isProductRequestLoading, mutate: productRequest } =
    usePostProductRequest({
      onSuccess: () => {
        successToast("Your request has been submitted successfully");
      },
      onError: () => {
        errorToast("You have already requested this product");
      },
    });

  const handleFirstCombination = (defaultVariationValues) => {
    const combination = _.zipObject(
      _.map(defaultVariationValues, "name"),
      _.map(defaultVariationValues, "value")
    );
    setUserSelectionValue(combination);
  };

  const findVariationCombination = (userSelection) => {
    let productVariations = localProductData.variations;

    for (let i = 0; i < productVariations.length; i++) {
      let variationValue = productVariations[i].values;

      let combination = {
        ...userSelection,
      };

      for (let i = 0; i < variationValue.length; i++) {
        combination[variationValue[i].name] = variationValue[i].value;
      }

      if (_.isEqual(userSelection, combination)) {
        setSelectedCombination(productVariations[i]);
        setCartedItemsCount(1);

        return;
      }
    }
  };

  // PRODUCT COMBINATION VARIATION COMBINATION END

  useEffect(() => {
    setActiveImage(() => (product?.pictures?.length ? pictures[0] : ""));
  }, [product]);

  const incrItemsCount = () => {
    if (
      selectedCombination &&
      selectedCombination.inventory <= cartedItemsCount
    )
      return errorToast(en["product.stock_limit_over"]);

    setCartedItemsCount((prevCount) => prevCount + 1);
  };

  const decrItemsCount = () => {
    setCartedItemsCount((prevCount) =>
      prevCount - 1 >= 1 ? prevCount - 1 : 1
    );
  };

  const structureCartProduct = () => {
    let selectedProduct = {
      product: product.slug,
      quantity: cartedItemsCount,
      price: product.default_variation.offer_price
        ? product.default_variation.offer_price
        : product.default_variation.price,
      product_id: product.product_id,
      wishlist_to_cart: false,
    };

    selectedProduct.variation = selectedCombination.variation;

    return selectedProduct;
  };

  const handleAddToCart = async () => {
    let selectedProduct = structureCartProduct();

    setIsAddToCartLoading(true);
    const res = await addToCart(selectedProduct);
    setIsAddToCartLoading(false);

    if (!res?.data) return;
    successToast(en["myCart.added_successfully"]);

    if (addToCartCallback) addToCartCallback();

    if (res?.data.warning) {
      warningToast(res.data.warning, 1000);
    }

    updateProductCartQuantity(
      selectedProduct?.variation,
      selectedProduct?.quantity
    );
  };

  const handleBuyNow = async () => {
    if (!(await isAuthenticated(router))) return;

    let selectedProduct = structureCartProduct();

    setIsBuyNowLoading(true);
    const res = await addToCart(selectedProduct);
    setIsBuyNowLoading(false);

    if (!res?.data) return;

    updateProductCartQuantity(
      selectedProduct?.variation,
      selectedProduct?.quantity
    );

    router.push("/checkout");
  };

  const updateProductCartQuantity = (variation = "", quantity) => {
    if (variation) {
      let updatedData = localProductData.variations.map((variationData) => {
        if (variationData.variation === variation) {
          return {
            ...variationData,
            in_cart_quantity: quantity,
          };
        } else {
          return variationData;
        }
      });

      let newProductData = {
        ...localProductData,
        variations: updatedData,
      };

      setLocalProductData(newProductData);
    }
  };

  const updateLocalProductData = (variation = "", wishlist) => {
    if (variation) {
      let updatedData = localProductData.variations.map((variationData) => {
        if (variationData.variation === variation) {
          return {
            ...variationData,
            in_wishList: wishlist,
          };
        } else {
          return variationData;
        }
      });

      let newProductData = {
        ...localProductData,
        variations: updatedData,
      };

      setLocalProductData(newProductData);
    }
  };

  const handleAttributeClick = (data) => {
    setUserSelectionValue({ ...userSelectionValue, [data.key]: data.value });
  };

  const handleWishlist = async () => {
    if (!(await isAuthenticated(router))) return;

    const productData = {
      product: product?.slug,
    };

    productData.variation = selectedCombination.variation;

    if (isWishlist) {
      setLoading(true);
      let res = await removeWishList(productData);
      setLoading(false);

      if (res && res?.data) {
        setSelectedCombination({
          ...selectedCombination,
          in_wishList: false,
        });
      }

      updateLocalProductData(productData.variation, false);
    } else if (!isWishlist) {
      setLoading(true);
      let res = await addToWishList(productData);
      setLoading(false);

      if (res && res?.data) {
        setSelectedCombination({
          ...selectedCombination,
          in_wishList: true,
        });
      }

      updateLocalProductData(productData.variation, true);
    }
  };

  const handleProductRequest = async () => {
    if (!(await isAuthenticated(router))) return;
    productRequest({
      product_slug: product.slug,
      product_variation: selectedCombination.variation,
    });
  };

  const getInventoryText = (inventory) => {
    if (inventory > minAvailabilityToShow) {
      return <p className="text-success-color">Available</p>;
    } else if (inventory < dangerStockAmount) {
      return <p className="text-error-color">{inventory}</p>;
    } else {
      return <p className="text-success-color">{inventory}</p>;
    }
  };

  if (!product) return <h1>{en["common.no_data_available"]}</h1>;

  return (
    <>
      <div
        className={`w-full flex flex-col md:flex-row items-start justify-between overflow-hidden ${
          isInModal ? "lg:min-h-[28rem]" : "lg:min-h-[33.75rem]"
        }`}
      >
        <div className="flex items-start space-x-[1.25rem] w-full mr-[0.625rem] md:w-[12%] order-2 md:order-none mt-[1.438rem] mb-[1.56rem] lg:my-0">
          <div
            className={`lg:w-full pr-[0.5rem] flex flex-row lg:flex-col items-center overflow-x-scroll lg:overflow-x-hidden

          ${isInModal ? "lg:h-[28rem]" : "lg:h-[33.75rem]"} ${
              Styles.customScrollBar
            }`}
          >
            {imageGallery && imageGallery.length > 0 ? (
              imageGallery.map((image) => (
                <div
                  className={`aspect-square	min-w-[4.5rem] lg:min-w-[100%] lg:max-w-[8.125rem] p-[.5rem] lg:p-[1rem] flex items-start justify-center border
                   hover:border-[#E81E61] rounded-[0.75rem] cursor-pointer mb-[.625rem] ${
                     image.path === activeImage.path
                       ? "border-[#E81E61]"
                       : "border-white"
                   }`}
                  key={image.path}
                  onClick={() => setActiveImage(image)}
                >
                  <div className="relative w-full h-full">
                    <Image
                      src={image.path}
                      alt={product.name}
                      className="rounded-[0.75rem] object-contain"
                      placeholder="blur"
                      blurDataURL={image?.path}
                      fill
                      sizes="33vw"
                    />
                  </div>
                </div>
              ))
            ) : (
              <div
                className={`aspect-square w-[100%] lg:max-w-[8.125rem] p-[.5rem] lg:p-[1rem] flex items-center justify-center border hover:border-[#E81E61] rounded-[0.75rem] cursor-pointer border-[#E81E61]`}
                onClick={() => setActiveImage(productPlaceholderlargeImage)}
              >
                <Image
                  src={productPlaceholderlargeImage.path}
                  width={smallImageSize}
                  height={smallImageSize}
                  className="rounded-[0.75rem]"
                  alt={product.name}
                />
              </div>
            )}
          </div>
        </div>

        <div className="min-w-full md:min-w-[38%] aspect-square bg-[#F5F8FA] flex items-center justify-center order-1 md:order-none rounded-[0.75rem]">
          <div className="aspect-square relative w-full h-full rounded-[0.75rem]">
            <Image
              src={activeImage?.path || productPlaceholderlargeImage.path}
              alt={product.name}
              placeholder="blur"
              blurDataURL={
                activeImage?.path || productPlaceholderlargeImage.path
              }
              className="rounded-[0.75rem] object-contain"
              fill
              sizes="33vw"
            />
          </div>
        </div>

        <div className="w-full md:w-[50%] h-full order-3 md:order-none  lg:px-[1.8rem]">
          {!isInModal && (
            <ProductDetailsBanner
              promotionalBannerInfo={promotionalBannerInfo}
            />
          )}

          <div>
            {product?.sku && (
              <p className="text-[0.875rem] text-[#414E5F]">
                {en["product.product_id"]} {product?.sku}
              </p>
            )}
            <h1 className="text-[1.5rem] text-[#0A101A] my-[0.625rem] line-clamp-2 ">
              {product.name}
            </h1>
            <div className="text-[0.875rem] flex items-center space-x-[3.563rem]">
              <div>
                <span className="text-[#7B838F] mr-1">
                  {en["common.brand"]}
                </span>
                <span className="text-[#E6457A]">
                  {product.brand.slug && (
                    <Link href={`/brands/${product.brand.slug}`}>
                      {product.brand.name}
                    </Link>
                  )}
                </span>
              </div>
              <div>
                <span className="text-[#7B838F] mr-1">
                  {en["common.category"]}
                </span>

                <span className="text-[#E6457A] cursor-pointer">
                  <Link href={`/category/${product?.categories[0]?.slug}`}>
                    {product?.categories[0]?.name || ""}
                  </Link>
                </span>
              </div>
            </div>
          </div>

          {product.reviews && (
            <div className="mt-[0.813rem] flex items-center space-x-[0.625rem] lg:mt-[1.563rem]">
              {product?.reviews?.total_rating_count > 0 && (
                <Rating
                  ratingNumber={product?.reviews?.average_product_rating}
                />
              )}
              {product?.reviews?.total_rating_count !== 0 ? (
                <p className="text-primary font-medium">
                  {product?.reviews?.total_rating_count} Reviews
                </p>
              ) : (
                <p className="text-primary font-medium">No Reviews</p>
              )}
            </div>
          )}

          <div className="h-[1px] bg-[#E5E3E4] my-[1.563rem]"></div>
          {!Object.keys(selectedCombination).length > 0 ? (
            product?.default_variation.offer_price > 0 ? (
              <div className="mb-[1.875rem] flex items-center space-x-[0.625rem]">
                <p className="text-[1.75rem] text-[#0A101A]">
                  ৳{product?.default_variation.offer_price}
                </p>
                <p className="text-[1.125] text-[#98989B] line-through">
                  ৳{product?.default_variation.price}
                </p>
                <div className="w-[3.75rem] h-[1.5rem] text-[0.75rem] text-white bg-[#FF912C] rounded-[5px] flex items-center justify-center">
                  <p>
                    {product?.default_variation.offer_percent}
                    {en["product.discount_percentage"]}
                  </p>
                </div>
              </div>
            ) : (
              <div className="mb-[1.875rem] flex items-center space-x-[0.625rem]">
                <p className="text-[1.75rem] text-[#0A101A]">
                  ৳{product?.default_variation.price}
                </p>
              </div>
            )
          ) : selectedCombination?.offer_price > 0 ? (
            <div className="mb-[1.875rem] flex items-center space-x-[0.625rem]">
              <p className="text-[1.75rem] text-[#0A101A]">
                ৳{selectedCombination?.offer_price}{" "}
              </p>
              <p className="text-[1.125] text-[#98989B] line-through">
                ৳{selectedCombination?.price}
              </p>
              <div className="w-[3.75rem] h-[1.5rem] text-[0.75rem] text-white bg-[#FF912C] rounded-[5px] flex items-center justify-center">
                <p>
                  {selectedCombination?.offer_percent}
                  {en["product.discount_percentage"]}
                </p>
              </div>
            </div>
          ) : (
            <div className="mb-[1.875rem] flex items-center space-x-[0.625rem]">
              <p className="text-[1.75rem] text-[#0A101A]">
                ৳{selectedCombination?.price}
              </p>
            </div>
          )}

          {!isInModal && (
            <div className="mb-[1.875rem] text-[1rem] text-[#0A101A] w-full">
              <HtmlRenderer
                visibleLineClass="line-clamp-none"
                htmlData={product.desc}
              />
            </div>
          )}
          {product.attributes.map((attribute) => (
            <div
              className="flex lg:items-center flex-col lg:flex-row mb-[1.275rem] space-y-5 lg:space-y-0"
              key={attribute.name}
            >
              <label htmlFor="Weight" className="text-[#7B838F] mr-[2.313rem]">
                {attribute.name}
              </label>
              <div>
                {attribute.values.map((attData) => (
                  <button
                    className={`border rounded-[3.125rem] py-[0.5rem] px-[1.125rem] mr-[0.625rem] mb-[0.625rem] hover:border-[#E91F63] ${
                      userSelectionValue[attribute.name] === attData.value &&
                      "border-[#E91F63] text-[#E91F63] bg-[#FCF5F7]"
                    }`}
                    key={attData.value}
                    onClick={() =>
                      handleAttributeClick({
                        key: attribute.name,
                        value: attData.value,
                      })
                    }
                  >
                    {attData.value}
                  </button>
                ))}
              </div>
            </div>
          ))}
          <div className="flex flex-col lg:flex-row lg:items-center  space-y-[1.25rem] lg:space-y-0">
            <label htmlFor="Quantity" className="text-[#7B838F] mr-[2.313rem]">
              Quantity
            </label>
            <div className="flex items-center">
              <div className="flex items-center justify-between w-[9.375rem] h-[3.125rem] border border-[#EBEEF2] rounded-[0.625rem]">
                <button
                  className={
                    "w-[2.5rem] h-full bg-[#ECF1F4] rounded-tl-[0.625rem] rounded-bl-[0.625rem] flex items-center justify-center"
                  }
                  onClick={decrItemsCount}
                  disabled={isDecrBtnDisabled}
                >
                  <MinusIcon
                    className={classNames(
                      {
                        "text-[#7B838F]": isDecrBtnDisabled,
                        "text-[#0a101a]": !isDecrBtnDisabled,
                      },
                      "h-[1rem] w-[1rem]]"
                    )}
                  />
                </button>
                <div>{cartedItemsCount}</div>

                <button
                  className="w-[2.5rem] h-full bg-[#ECF1F4] rounded-tr-[0.625rem] rounded-br-[0.625rem] flex items-center justify-center"
                  onClick={incrItemsCount}
                  disabled={isIncrBtnDisabled}
                >
                  <PlusIcon
                    className={classNames(
                      {
                        "text-[#7B838F]": isIncrBtnDisabled,
                        "text-[#0a101a]": !isIncrBtnDisabled,
                      },
                      "h-[1rem] w-[1rem]]"
                    )}
                  />
                </button>
              </div>

              <div className="flex items-center ml-[1.25rem] space-x-[4px]">
                {inventory ? (
                  <>
                    <p>{en["common.availability"]}:</p>
                    {getInventoryText(inventory)}
                  </>
                ) : (
                  <p className="text-[#FF4242]">Out of stock</p>
                )}
              </div>
            </div>
          </div>

          <div className="mt-[3.063rem] flex flex-col items-start gap-[1rem] min-[1386px]:flex-row">
            <div className="flex items-center space-x-[1rem]">
              {inventory > 0 ? (
                <>
                  <PrimaryBtn
                    btnText={en["product.buy_now"]}
                    className="h-[3rem] w-[9.5rem] md:w-[11.25rem]"
                    onClick={handleBuyNow}
                    disabled={
                      Object.keys(selectedCombination).length > 0
                        ? selectedCombination.inventory === 0
                        : product?.default_variation.inventory === 0
                    }
                    isLoading={isBuyNowLoading}
                  />
                  <TertiaryBtn
                    btnText={en["myCart.add_to_cart"]}
                    className="w-[9.5rem] md:w-[11.25rem] h-[3rem] "
                    onClick={handleAddToCart}
                    disabled={
                      Object.keys(selectedCombination).length > 0
                        ? selectedCombination.inventory === 0
                        : product?.default_variation.inventory === 0
                    }
                    isLoading={isAddToCartLoading}
                  />
                </>
              ) : (
                <SecondaryBtn
                  btnText="Request Product"
                  className="w-[9.5rem] md:w-[11.25rem] h-[3rem] text-primary font-normal bg-[#FFEDF2]"
                  isLoading={isProductRequestLoading}
                  onClick={handleProductRequest}
                />
              )}
            </div>
            {!isInModal && (
              <div
                className="flex items-center max-[1386px]:mt-[1rem] cursor-pointer relative"
                onClick={handleWishlist}
              >
                {isLoading && (
                  <div className="absolute inset-0 z-50 bg-[#FFFFFFcc]">
                    <InlineLoader />
                  </div>
                )}

                {isWishlist && (
                  <Image
                    src="/assets/icons/wishlist-active.svg"
                    width={48}
                    height={48}
                    alt="wishlist"
                  />
                )}
                {!isWishlist && (
                  <Image
                    src="/assets/icons/wishlist-inactive.svg"
                    width={48}
                    height={48}
                    alt="wishlist"
                  />
                )}
                <p className="text-[1.125] text-[#1F2A3B] hover:text-[#E81E61] hover:underline active:text-[#E81E61] active:underline">
                  {isWishlist
                    ? en["myWishlist.added_to"]
                    : en["myWishlist.add_to"]}
                </p>
              </div>
            )}

            {isInModal && (
              <div
                className={`flex items-center mt-[40px] lg:mt-0 cursor-pointer`}
              >
                <Link href={`/product/${product.slug}`}>
                  <p className="underline text-[#1F2A3B] hover:text-[#E81E61] active:text-[#E81E61]">
                    {en["common.view_details"]}
                  </p>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

Overview.propTypes = {
  breadcrumbs: PropTypes.object,
};
