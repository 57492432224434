import React from "react";
import classNames from "classnames";
import InlineLoader from "@/components/loader/InlineLoader";
import PropTypes from "prop-types";

function TertiaryBtn({
  onClick,
  btnText,
  border = "1px",
  className,
  disabled = false,
  isLoading,
  ...otherProps
}) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        {
          "text-[#AEB4BC] border-[#CBD0D6]": disabled,
          "text-[#E81E61] border-[#E81E61] hover:border-[#CF1B57] active:border-[#CF1B57]":
            !disabled,
        },
        `border-solid rounded-[10px] relative ${className}`
      )}
      style={{ border: `${border} solid` }}
      disabled={disabled || isLoading}
      {...otherProps}
    >
      {isLoading && <InlineLoader className="text-[#1F2A3B]" />}
      <p
        className={classNames(
          {
            invisible: isLoading,
          },
          "inline-block"
        )}
      >
        {btnText}
      </p>
    </button>
  );
}

export default TertiaryBtn;

TertiaryBtn.propTypes = {
  onClick: PropTypes.func,
  btnText: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  border: PropTypes.bool,
};
