import Image from "next/image";
import Link from "next/link";

function ProductDetailsBanner({ promotionalBannerInfo }) {
  if (!promotionalBannerInfo?.status) return null;

  return (
    <Link href={promotionalBannerInfo.promotional_banner_redirect_link}>
      <div className="aspect-[960/216]  md:aspect-[1836/252] relative">
        <Image
          src={promotionalBannerInfo.product_details_promotional_website_banner}
          alt="product-banner"
          fill
          className="rounded-lg hidden md:block object-cover"
        />

        <Image
          src={
            promotionalBannerInfo.product_details_promotional_responsive_banner
          }
          alt="product-banner"
          fill
          className="rounded-lg md:hidden object-cover"
        />
      </div>
    </Link>
  );
}

export default ProductDetailsBanner;
