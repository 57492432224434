import ArrowIcon from "@/icons/primary-arrow.svg";

function ShowMoreButton({ onClick, isExpended }) {
  return (
    <button
      onClick={onClick}
      className="text-primary-default font-semibold mt-[.6rem]"
    >
      {isExpended ? (
        <div className="flex items-center space-x-[13px] ">
          <span>Show Less</span>
          <ArrowIcon className="rotate-[270deg] mt-[4px]" />
        </div>
      ) : (
        <div className="flex items-center space-x-[13px] ">
          <span>Show More</span>
          <ArrowIcon className="rotate-[-270deg] mt-[3px]" />
        </div>
      )}
    </button>
  );
}

export default ShowMoreButton;
