import React from "react";

export const CartModalSkeleton = () => {
  return (
    <div className="w-full flex flex-col md:flex-row items-start justify-between overflow-hidden lg:min-h-[28rem] animate-pulse">
      <div className="flex items-start space-x-[1.25rem] w-full mr-[0.625rem] md:w-[12%] order-2 md:order-none mt-[1.438rem] mb-[1.56rem] lg:my-0">
        <div className="lg:w-full pr-[0.5rem] flex flex-row gap-3 lg:flex-col items-center overflow-x-scroll lg:overflow-x-hidden lg:h-[28rem]">
          <div className="bg-[#DBE0E6] aspect-square w-[100%] lg:max-w-[8.125rem] p-[.5rem] lg:p-[1rem] flex items-center justify-center border  rounded-[0.75rem] cursor-pointer" />

          <div className="bg-[#DBE0E6] aspect-square w-[100%] lg:max-w-[8.125rem] p-[.5rem] lg:p-[1rem] flex items-center justify-center border  rounded-[0.75rem] cursor-pointer" />
        </div>
      </div>

      <div className="min-w-full md:min-w-[38%] aspect-square bg-[#DBE0E6] flex items-center justify-center order-1 md:order-none rounded-[0.75rem]">
        <div className="aspect-square relative w-full h-full rounded-[0.75rem]"></div>
      </div>

      <div className="w-full md:w-[50%] h-full order-3 md:order-none px-[1.8rem]">
        <div>
          <p className="bg-[#DBE0E6] rounded-md h-[1rem] w-[5rem]" />

          <p className="my-[0.625rem] h-[1.5rem] bg-[#DBE0E6] rounded-md w-full" />
          <p className="my-[0.625rem] h-[1.5rem] bg-[#DBE0E6] rounded-md w-full" />

          <div className="text-[0.875rem] flex items-center space-x-[3.563rem]">
            <div>
              <p className="bg-[#DBE0E6] rounded-md h-[1rem] w-[8rem]"></p>
            </div>

            <div>
              <p className="bg-[#DBE0E6] rounded-md h-[1rem] w-[8rem]"></p>
            </div>
          </div>
        </div>

        <div className="h-[1px] bg-[#DBE0E6] my-[1.563rem]"></div>
        <div className="bg-[#DBE0E6] mb-[1.875rem] rounded-md h-[1.75rem] w-[15rem]" />

        <div className="bg-[#DBE0E6] mb-[1.275rem]  rounded-md h-[1.75rem] w-[80%]" />
        <div className="bg-[#DBE0E6] mb-[1.275rem]  rounded-md h-[1.75rem] w-[90%]" />

        <div className="flex gap-3 items-center">
          <div className="bg-[#DBE0E6] rounded-md h-[1.75rem] w-[20%]" />
          <div className="bg-[#DBE0E6] rounded-md h-[2.25rem] w-[40%]" />
          <div className="bg-[#DBE0E6] rounded-md h-[1.75rem] w-[30%]" />
        </div>

        <div className="mt-[3rem] flex gap-3 items-end">
          <div className="bg-[#DBE0E6] rounded-md h-[3rem] w-[40%]" />
          <div className="bg-[#DBE0E6] rounded-md h-[3rem] w-[40%]" />
          <div className="bg-[#DBE0E6] rounded-md h-[1.75rem] w-[20%]" />
        </div>
      </div>
    </div>
  );
};
